import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FaMapMarkerAlt } from "react-icons/fa/index.esm";
import { graphql, useStaticQuery } from "gatsby";
import { mergePrismicPreviewData } from "gatsby-source-prismic";

// import Banner from "../components/Banner";
import { HTMLContent } from "components/Content";
import Layout from "components/Layout";
import SEO from "components/Seo";
import CtaButton from "components/CtaButton";

const FindUsPage = () => {
  const data = useStaticQuery(graphql`
    query findusPageQuery {
      prismicFindus {
        data {
          title {
            text
          }
          body {
            html
          }
        }
      }
    }
  `);

  const IS_BROWSER = typeof window !== "undefined";
  const previewData = IS_BROWSER && window.__PRISMIC_PREVIEW_DATA__;
  const staticData = data;

  const contentMerged = mergePrismicPreviewData({ staticData, previewData });
  const content = contentMerged.prismicFindus.data;

  return (
    <Layout pageInfo={{ pageName: "find-us" }}>
      <SEO title="Find Us" />
      {/* <Banner title="Find Us" /> */}
      <section>
        <Container>
          <Row>
            <Col xs="12">
              <h2>{content.title.text}</h2>

              <HTMLContent content={content.body.html} />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="dark">
        <Container>
          <Row>
            <Col xs="12" md="4">
              <h4>Classroom Location</h4>

              <p>
                D&D Security Training Academy <br />
                5400 Eisenhower Ave <br />
                Alexandria, Virginia 22304 <br />
                <a
                  href="https://goo.gl/maps/RK1spbPsMrE3j5bR7"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn-directions"
                >
                  <FaMapMarkerAlt /> Get Directions
                </a>
              </p>
            </Col>
            <Col xs="12" md="8">
              <iframe
                title="classroom location"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3109.401347785598!2d-77.1298057!3d38.8003562!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b7b2470fe1783f%3A0xac8f88b25ec6023d!2s5400%20Eisenhower%20Ave%2C%20Alexandria%2C%20VA%2022304!5e0!3m2!1sen!2sus!4v1566777657876!5m2!1sen!2sus"
                width="100%"
                height="400"
                frameBorder="0"
                style={{ border: 0 }}
                allowFullScreen=""
              />
            </Col>
          </Row>
        </Container>
      </section>

      <section className="bluedark">
        <Container>
          <Row>
            <Col xs="12" md="4">
              <h4>The Range</h4>

              <p>
                62 Potomac Creek Dr. <br />
                Fredericksburg, Virginia 22405 <br />
                <a
                  href="https://goo.gl/maps/Fcufj9cxtiqp87WX6"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn-directions"
                >
                  <FaMapMarkerAlt /> Get Directions
                </a>
              </p>
            </Col>
            <Col xs="12" md="8">
              <iframe
                title="range location"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1563.8343116696503!2d-77.4427168!3d38.3797867!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b6e93963e0c7a1%3A0x8bcfd1d12dc69638!2sThe%20Range%20Stafford!5e0!3m2!1sen!2sus!4v1566777767418!5m2!1sen!2sus"
                width="100%"
                height="400"
                frameBorder="0"
                style={{ border: 0 }}
                allowFullScreen=""
              />
            </Col>
          </Row>
        </Container>
      </section>

      <section>
        <Container>
          <Row>
            <Col lg="10" className="mx-auto text-center">
              <p className="mini-lead">Get Started</p>
              <h2>Your Future in Security Starts Here</h2>
              <p className="intro">
                Enroll now to start your journey towards a better future.
              </p>

              <CtaButton
                label="View Classes"
                url="/class-schedules"
                className="mt-3"
              />
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export default FindUsPage;
